import Vue from 'vue';
import Vuex from 'vuex';
import apiHttpService, { httpClient } from '@/core/http/api.http.service';
import storeActions from './actions';

Vue.use(Vuex);

import {
  keyCompanyName,
  limparStorage,
  keysStorage,
} from '@/core/utils/auth.util';

const captchaSiteKey = process.env.VUE_APP_CAPTCHA_PUBLIC_KEY;
const token = localStorage.getItem(`${keyCompanyName}token`);
const codigo = localStorage.getItem(`${keyCompanyName}codigo`);
const nome = localStorage.getItem(`${keyCompanyName}nome`);
const ehPrimeiroAcesso = localStorage.getItem(
  `${keyCompanyName}ehPrimeiroAcesso`
);
const cpf = localStorage.getItem(`${keyCompanyName}cpf`);
const logoUsuario = localStorage.getItem(`${keyCompanyName}logo`);

export default new Vuex.Store({
  state: {
    toggleMenu: true,
    workspaces: [],
    usuarioLogado: {
      codigo: codigo ? codigo : null,
      token: token ? token : '',
      nome: nome,
      ehPrimeiroAcesso: ehPrimeiroAcesso,
      cpf: cpf,
      logo: logoUsuario ? logoUsuario : null,
      owner: false,
    },
    permissao: {
      admin: false,
      owner: false,
      credencial: {},
    },
  },
  getters: {
    nomeUsuarioLogado: (state) => state.usuarioLogado.nome,
    nomeRazaoSocialUsuarioLogado: (state) => '',
    cpfUsuarioLogado: (state) => state.usuarioLogado.cpf,
    usuarioEstalogado: (state) => !!state.usuarioLogado.token,
    token: (state) => state.usuarioLogado.token,
    logo: (state) => state.usuarioLogado.logo,
    owner: (state) => state.usuarioLogado.owner,
    toggleMenuShow: (state) => state.toggleMenu,
    workspaces: (state) => state.workspaces,
  },
  actions: {
    [storeActions.AUTH_LOGOUT_REQUEST]: (
      { state, commit, dispatch }: any,
      payload: any
    ) => {
      return new Promise((resolve, reject) => {
        commit(storeActions.AUTH_LOGOUT);
        resolve(true);
      });
    },
    [storeActions.AUTH_REQUEST]: ({ commit, dispatch }: any, payload: any) => {
      return new Promise((resolve, reject) => {
        apiHttpService
          .post<any>(`/authorization`, payload)
          .then((result) => {
            if (result.success) {
              httpClient.defaults.headers.common['Authorization'] =
                httpClient.defaults.headers.common[
                  'Authorization'
                ] = `bearer ${result.data.token}`;
              commit(storeActions.AUTH_SUCCESS, result.data);
            }
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    [storeActions.AUTH_SUCCESS]: (state: any, payload: any) => {
      state.usuarioLogado = payload;
      localStorage.setItem(`${keyCompanyName}codigo`, payload.codigo);
      localStorage.setItem(`${keyCompanyName}token`, payload.token);
      localStorage.setItem(`${keyCompanyName}nome`, payload.nome);
      localStorage.setItem(
        `${keyCompanyName}ehPrimeiroAcesso`,
        payload.ehPrimeiroAcesso
      );
      localStorage.setItem(`${keyCompanyName}cpf`, payload.cpf);
      localStorage.setItem(`${keyCompanyName}workspace`, payload.workspace);
      state.codWorkspace = payload.workspace;
      state.usuarioLogado.codigo = payload.codigo;
      state.usuarioLogado.token = payload.token;
      state.usuarioLogado.nome = payload.nome;
      state.usuarioLogado.cpf = payload.cpf;
      state.usuarioLogado.razaoSocial = payload.razaoSocial;
      state.usuarioLogado.ehPrimeiroAcesso = payload.ehPrimeiroAcesso;

      if (payload.logo)
        localStorage.setItem(`${keyCompanyName}logo`, payload.logo);
    },
    [storeActions.AUTH_LOGOUT]: (state: any, payload: any) => {
      state.usuarioLogado = {
        codigo: null,
        token: '',
        nome: '',
        ehPrimeiroAcesso: false,
        cpf: '',
        logo: '',
      };
      limparStorage();
    },
    [storeActions.TOGGLE_MENU]: (state: any, payload: any) => {
      state.toggleMenu = !state.toggleMenu;
    },
    [storeActions.CREDENTIALS]: (state: any, payload: any) => {
      const obj = payload.credenciais.reduce((accumulator: any, value: any) => {
        return { ...accumulator, [value]: true };
      }, {});

      state.permissao = {
        credencial: obj,
        admin: payload.admin,
        owner: payload.owner,
      };
    },
    [storeActions.WORKSPACES]: (state: any, payload: any) => {
      state.workspaces = payload;
    },
  },
});
